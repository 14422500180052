





















































import { Component, Ref, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { searchController } from '@/components/search/search.controller'
import { promiseHelper } from '@/helper/promise-helper'

@Observer
@Component({
  components: {
    users: () => import('@/modules/search/components/containers/users.vue'),
  },
})
export default class SearchInputPage extends Vue {
  searchController = searchController
  @Ref('searchInput') searchInput

  async mounted() {
    await promiseHelper.delay(200)
    this.searchInput && this.searchInput.focus()
  }
}
